@font-face {
  font-family: 'Assistant';
  src: url('./assets/fonts/Assistant-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Pacifico';
  src: url('./assets/fonts/Pacifico-Regular.ttf');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeue-Regular.ttf');
}


h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

:root {
  --primary-color: #8B252A;
  --secondary-color: #FFFFFF;
  --accent-color: #09504C;
  --third-color: #FFC227;
}

html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body, html {
  padding: 0;
  scroll-snap-type: y mandatory;
}

a {
  text-decoration: none;
  color: inherit;
}

.navbar {
  height: 10vh;
  position: fixed;
  top: 1rem;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s;
  z-index: 1000;
}

.logo-container {
  max-width: 50%;
}

.logo, .logo-alternate {
  margin-left: 0.104vw;
  width: 100%;
}

.icon-insta, .nav-link {
  transition: fill 0.3s, color 0.3s;
}

.logo, .logo-alternate {
  margin-left: 2rem;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 65px;
  margin-right: 5rem;
}

.nav-link {
  color: var(--secondary-color);
  font-family: 'Assistant';
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.nav-link:before {
  content: "";
  position: absolute;
  width: 2vw;
  height: 0.104vw;
  bottom: 35px;
  border-bottom: 2px solid var(--secondary-color);
}

.navbar.section1 .icon-insta,
.navbar.section1 .menu-icon,
.navbar.section1 .nav-link {
  color: var(--secondary-color);
  fill: var(--secondary-color);
  stroke: none;
}

.navbar.section2 .icon-insta,
.navbar.section2 .menu-icon,
.navbar.section2 .nav-link {
  color: var(--primary-color);
  fill: var(--primary-color);
}

.navbar.section2 .nav-link:before {
  border-bottom: 2px solid var(--primary-color);
}

.navbar.section3 .icon-insta,
.navbar.section3 .menu-icon,
.navbar.section3 .nav-link {
  color: var(--secondary-color);
  fill: var(--secondary-color);
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
}

#section1 {
  background-color: var(--primary-color);
}

#section2 {
  background-color: var(--secondary-color);
  position: relative;
}

#section3 {
  background-color: var(--accent-color);
  position: relative;
}

.hero-content {
  width: 100%;
  height: 100vh;
  background-image: url('./assets/images/Fond_Dalou_Bannière_rouge_V2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero-title span {
  color: var(--secondary-color);
  text-align: center;
  font-family: Pacifico;
  font-size: 4.5vw;
  line-height: 3vw;
}

.hero-title {
  color: var(--secondary-color);
  text-align: center;
  font-family: "Bebas Neue";
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 4.5vw;
  width: 100%;
  padding-top: 18vh;
  margin-left: auto;
  margin-right: auto;
}
.address  {
  width: 100%;
}

.hero-separator {
  width: 5vw;
  height: 2px;
  background: var(--secondary-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5vw;
}

.hero-subtitle {
  color: var(--secondary-color);
  text-align: center;
  font-family: Assistant;
  font-size: 1.3vw;
  margin-top: 1.5vw;
}

.hero-button {
  border-radius: 1.028vw;
  border: 1px solid var(--secondary-color);
  background: rgba(0, 0, 0, 0.39);
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.3vh;
  padding: 0.26vw 1.04vw;
  width: fit-content;
  color: var(--secondary-color);
  font-family: Pacifico;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
}

.scroll-icon {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: block;
}

.hero-bubble {
  width: 8vw;
  height: 8vw;
  background-color: var(--secondary-color);
  border-radius: 50%;
  text-align: center;
}

.bubble1 {
  position: absolute;
  margin-top: 40vh;
  margin-left: 15vw;
}

.bubble-span {
  color: var(--accent-color);
  margin-top: 1.9vh;
  display: block;
  font-family: Pacifico;
  width: 100%;
  font-size: 0.9vw;
  line-height: 1.3vw;
}

.bubble-separator {
  width: 1.30vw;
  margin: auto;
  display: block;
  margin-top: 1vw;
  height: 0.104vw;
  background: var(--accent-color);
}

.bubble2 {
  display: none;
}

.section2-subsection {
  width: 90vw;
  padding-top: 14vh;
  height: 70vh;
}

.subsection {
  width: 90vw;
  height: 74vh;
  padding-top: 14vh;
  padding-bottom: 10vh;
  padding-left: 10vw;
}

.subsubsection {
  width: 90vw;
  height: 100%;
}

.subsubsubsection {
  width: 90vw;
  height: 70%;
}

.hero2-title {
  display: block;
}

.hero2-span {
  color: #8B252A;
  font-family: 'Pacifico';
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 6vw;

}

.hero2-span2 {
  color: #8B252A;
  font-family: "Bebas Neue";
  font-size: 5.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 5vw;
}

.absolute-yellow {
  position: absolute;
  float: left;
  height: 84vh;
  margin-top: 16vh;
  width: 75px;
  background: var(--third-color);
}

.container-section2-content {
  display: flex;
  gap: 10px;
  margin-top: 1.5%;
  height: 100%;
  position: relative;
}

.column1 {
  width: 40%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.column2 {
  width: 60%;
}

.description {
  color: #002A3C;
  font-family: 'Assistant';
  font-size: 1.2vw;
  margin: 0;
  font-style: normal;
  width: 80%;
  font-weight: 600;
}

.description span {
  color: #002A3C;
  font-family: 'Pacifico';
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
}

.section2-buttons {
  display: flex;
  gap: 16px;
  cursor: pointer;
  color: #8B252A;
  font-family: 'Pacifico';
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 2%;
}

.section2-buttons>a>div {
  border-radius: 18.477px;
  border: 2.102px solid #8B252A;
  background: #FFF;
  padding: 0.4vw 1.5vw;
}

.info-section {
  font-family: 'Arial', sans-serif;
  color: #333333;
  width: 60%;
}

.info-title {
  color: #007474;
  font-family: 'Pacifico';
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.3em;
}

.info-title span {
  flex: 1;
  white-space: nowrap;
}

.title-sideline {
  flex-grow: 1;
  height: 1px;
  top: 2px;
  background-color: #09504C;
  margin-left: 0.5em;
  margin-top: 0.7em;
}

.info-content {
  margin-top: 2%;
  border-bottom: 1px solid #09504C;
  padding: 0vw 0;
}

.info-item {
  color: #007474;
  font-family: 'Assistant';
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2vw;
  display: flex;
  align-items: center;
  margin: 0.78vw 0;
}

.info-icon {
  margin-right: 10px;
  font-size: 1.2em;
  color: #09504C;
}

.images {
  width: 98%;
  margin-left: 2%;
  height: 100%;
  gap: 2%;
  overflow: hidden !important;
  overflow-x: hidden;
  display: flex;
}

.left-image {
  width: 49%;
  height: 100%;
  border-radius: 20px;
}

.right-image {
  width: 49%;
  height: 100%;
  border-radius: 20px;
}

.img-section2 {
  width: 100%;
  height: inherit;
  object-fit: cover;
  border-radius: 20px;
}

.section2-scroll {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: block;
}

.section3-title {
  width: 100%;
  padding-top: 8vw;
  text-align: center;
}

.section3-title .title1 {
  color: #FFF;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3vw;
}

.section3-title .title2 {
  color: #FFF;
  font-family: Pacifico;
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5vw;
}

.img-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2vw;
}

.arrow-left, .arrow-right {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.slider {
  width: 70vw;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  /* cursor: grab; */
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slide {
  display: inline-flex;
  align-items: center;
}

.slide img {
  width: 13vw;
  height: auto;
  margin: 10px;
}

.slider.active {
  cursor: grabbing;
}

.footer {
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 0;
  height: 8vw;
  padding-bottom: 3vw;
  width: 100vw;
  background: var(--secondary-color);
}

.underfooter {
  display: flex;
  position: absolute;
  bottom: 0;
  height: 2vw;
  width: 100vw;
  justify-content: center;
  background: var(--primary-color);
  align-items: center;
}

.underfooter p {
  color: #FFF;
  font-family: Assistant;
  font-size: 0.72;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.footer-content, .footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.brand-name {
  width: 33vw;
}


.content-container {
  width: 45vw;
  display: flex;
  align-items: center;
}

.leftcol-title {
  color: #8B252A;
  font-family: 'Pacifico';
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7vw;
}

.leftcol-content {
  color: #8B252A;
  font-family: 'Assistant';
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1vw;
  align-items: center;
  display: flex;
  gap: 7px;
}

.footer-separator {
  display: block;
  width: 3px;
  height: 70px;
  margin-left: 2vw;
  margin-right: 2vw;
  background: var(--primary-color);
}

.footer-btn {
  color: #8B252A;
  font-family: Pacifico;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  padding: 0.26vw 1.04vw;
  border-radius: 16.437px;
  border: 2px solid #8B252A;
  background: #FFF;
  margin-right: 3vw;
}

.img-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-left, .arrow-right {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.slider {
  flex: 1;
  overflow-x: auto;
  display: flex;
  /* cursor: grab; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slide {
  display: inline-flex;
  align-items: center;
}

.slide img {
  flex: 0 0 auto;
  height: auto;
  margin: 10px;
}

.slider.active {
  cursor: grabbing;
}

.insta-btn {
  color: #FFF;
  font-family: Pacifico;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  border-radius: 16.039px;
  border: 1.825px solid #FFF;
  padding: 0.26vw 1.04vw;
  width: fit-content;
  margin: auto;
  display: block;
  margin-top: 2vw;
  cursor: pointer;
}

@media only screen and (min-width: 1920px) {
  .slide img {
    width: 10vw;
  }
}

@media only screen and (min-width: 2200px) {
  .slide img {
    width: 8vw;
  }
}
  
@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-content, .footer-links {
    align-items: center;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1920px) {
  .nav-link:before {
    margin-bottom: -15px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1535px) {
  .nav-link:before {
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .nav-link:before {
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1279px) {
  .nav-link:before {
    margin-bottom: -25px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .nav-link {
    font-size: 1.4vw;
  }

  .nav-link:before {
    margin-bottom: 10px;
  }

  .hero-title {
    padding-top: 19vh;
    font-size: 8vw;
    line-height: 8vw;
  }

  .hero-title span {
    font-size: 8vw;
    line-height: 8vw;
  }

  .hero-separator {
    margin-top: 5vw;
  }

  .hero-subtitle {
    font-size: 2vw;
    margin-top: 6vw;
  }

  .hero-button {
    margin-top: 8vw;
    font-size: 2.5vw;
  }

  .hero-bubble {
    width: 12vw;
    height: 12vw;
  }

  .bubble-span {
    font-size: 1.5vw;
    line-height: 1.6vw;
  }

  .absolute-yellow {
    width: 6vw;
  }

  .subsubsubsection {
    height: 80%;
    margin-top: 3%;
  }

  .hero2-spann, .hero2-span2 {
    font-size: 6vw;
    line-height: 6vw;
  }

  .description {
    font-size: 2vw;
    width: 90%;
  }

  .description span {
    font-size: 2vw;
  }

  .section2-buttons {
    font-size: 2vw;
    display: block;
  }

  .button1 {
    text-align: center;
  }

  .button1:last-child {
    margin-top: 16%;
  }

  .info-title {
    font-size: 2vw;
  }

  .info-item {
    font-size: 2vw;
    line-height: 2vw;
  }

  .section3-title {
    padding-top: 20vh;
  }

  .section3-title .title1, .section3-title .title2 {
    font-size: 5vw;
    line-height: 5vw;
  }

  .img-carousel {
    margin-top: 5%;
  }

  .slide img {
    width: 25%;
  }

  .insta-btn {
    margin-top: 5%;
    font-size: 3vw;
  }

  .logo, .logo-alternate {
    width: 64%;
  }

  .footer {
    height: 15vw;
  }

  .footer-separator {
    width: 2px;
  }

  .footer-btn {
    border-width: 1px;
    font-size: 1vw;
  }

  .underfooter p {
    font-size: 1vw;
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 767px){
  .nav-link {
    display: none;
  }

  .nav-items {
    margin-right: 1rem;
  }

  .hero-content {
    background-image: none;
  }

  .hero-title {
    text-align: right;
    width: unset;
    margin-left: 0;
    margin-right: 10%;
    font-size: 6vw;
    line-height: 12vw;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .hero-title span {
    font-size: 16vw;
  }

  .herospan1 {
    font-size: 12vw !important;
    font-family: "Bebas Neue" !important;
  }

  .hero-separator {
    margin-right: 10%;
    width: 10vw;
  }

  .hero-subtitle {
    text-align: right;
    font-size: 4.5vw;
    margin-top: 5%;
    width: unset;
    margin-right: 10%;
  }

  .slider-container {
    position: relative;
    width: 75%;
    margin: 16% auto;
  }
  
  .slider {
    width: 100%;
    display: flex;
    overflow-x:scroll;
    scroll-behavior: smooth;
  }
  
  .slider img {
    width: 100%;
    flex-shrink: 0;
  }

  .sldier img  {
    display: flex;
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #FFF;
    padding: 10px 20px;
    z-index: 10;
  }
  
  .prev {
    left: -45px;
  }
  
  .next {
    right: -45px;
  }
  
  .hero-bubble {
    width: 30vw;
    height: 30vw;
    z-index: 10;
  }

  .bubble1 {
    margin-top: 38vh;
  }

  .bubble-span {
    font-size: 4vw;
    line-height: 5vw;
  }
  
  .bubble-separator {
    margin-top: 1.5vh;
  }

  .hero-button {
    background: transparent;
    margin-top: 0vh;
    font-size: 5vw;
    border-radius: 10px;
    padding: 5px 30px;
  }

  .absolute-yellow {
    display: none;
  }

  .subsection {
    height: 100%;
    width: 90vw;
    padding-left: 10vw;
    padding-top: 12vh;
  }

  .hero2-span {
    font-size: 10vw;
    line-height: 10vw;
  }

  .hero2-span2 {
    line-height: 10vw;
    font-size: 10vw;
  }

  .description {
    font-size: 4vw;
    margin-top: 2%;
  }

  .description span {
    font-size: 4vw;
  }

  .section2-buttons {
    display: block;
    width: fit-content;
    margin-top: 5%;
  }

  .section2-buttons .button1 {
    font-size: 5vw;
    border-radius: 12px;
  }

  .container-section2-content {
    display: none;
  }

  .section-2-illustration {
    width: 100%;
    margin-left: auto;
    text-align: center;
    position: relative;
    background: transparent;
  }

  .mobileIMG {
    width: 80%;
    position: inherit;
    z-index: 2;
  }

  .mobileBG {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .scnd-slider-box {
    position: relative;
    width: 100vw;
    margin-top: 5vw;
    margin-left: -10vw;
  }

  .mobile-yellow-bg {
    position: absolute;
    right: 0;
    top: 6.5%;
    height: 75%;
    width: 20%;
    background: var(--third-color);
  }

  .scnd-slider-container {
    width: 85%;
    position: relative;
    margin: 10% auto;
  }

  .scnd-slider { 
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .scnd-slider img {
    width: 100%;
    flex-shrink: 0;
  }

  .slider-dots {
    text-align: center;
    margin-top: 10px;
  }
  
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .dot.active {
    background-color: #717171; 
  }
  

  #section2 {
    min-height: 100vh;
    height: 100%;
    padding-bottom: 2vh;
  }

  body {
    scroll-snap-type: none;
  }
  
  .info-section {
    margin-top: 5vh;
    width: 85%;
  }

  .info-title {
    font-size: 5vw;
  }

  .info-item {
    font-size: 4vw;
    line-height: 5vw;
  }

  .info-content {
    padding: 4vw 0;
  }

  #section3 {
    min-height: 145vh;
    height: 100%;
  }

  .section3-title {
    padding-top: 15vh;
  } 

  .section3-title .title1 {
    font-size: 7vw;
    line-height: 10vw;
  }

  .section3-title .title2 {
    font-size: 12vw;
    line-height: 10vw;
  }

  .thirdSlider {
    margin: 10% auto !important;
  }

  .insta-btn {
    font-size: 5vw;
    padding: 5px 20px;
  }

  .footer {
    height: 55vh;
    display: block;
    width: 100vw;
    margin: auto;
  }

  .brand-name {
    margin: auto;
    width: 50vw;
  }

  .brand-name .logo-alternate {
    margin-left: 0 !important;
  }

  .footer-content {
    display: block;
  }

  .content-container {
    display: block;
    width: 80vw;
    padding: 0 10vw;
  }

  .leftcol {
    display: flex;
    flex-direction: column;
  }

  .leftcol-title {
    font-size: 5vw;
    text-align: left;
    margin-top: 2vh;
    line-height: 5vw;
    margin-bottom: 5vw;
  }

  .leftcol-content {
    font-size: 4vw;
    line-height: 5vw;
    margin-top: 2vw;
    display: flex;
    align-items: center;
  }

  .footer-separator {
    width: 80vw;
    margin: auto;
    height: 2px;
  }

  .footer-btn {
    width: fit-content;
    margin: 3vw auto;
    font-size: 5vw;
    padding: 5px 20px;
    border: 2px solid #8B252A;
    margin-bottom: 3vh;
  }

  .underfooter {
    height: 10vw;
  }
  .underfooter p {
    font-size: 3vw;
    font-weight: 500;
  }
}

@media only screen and (max-width: 400px){
  .slider-container {
    margin: 15% auto;
  }

  .scroll-icon {
    display: none;
  }

  .hero-button {
    margin-top: -2vh;
  }

  #section3 {
    min-height: 180vh;
  }

  .footer {
    height: 70vh;
  }
  
  body, html {
    scroll-snap-type: none;
  }

  section {
    scroll-snap-align: none;
  }
}